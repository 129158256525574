import { gql } from "@apollo/client";
import { METADATA_CORE_DATA } from "graphql/fragments/metadataCoreData";

export const GET_PRICING_PAGE_CONTENT = gql`
  query GetPricingPageContent {
    deputyRolesAndPricingPage {
      id
      _id
      createdAt
      updatedAt
      Section1Headline
      Section1Body
      JobDetails {
        id
        _id
        JobRoles {
          id
          _id
          JobRole
        }
        JobTitle
        originalPrice
        discountedPrice
      }
      Section3Headline
      Section3Body
      FAQs {
        id
        _id
        Question
        Answer
      }
      Metadata {
        ...MetadataCoreData
      }
      published_at
    }
  }

  ${METADATA_CORE_DATA}
`;
