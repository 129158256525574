import { gql } from "@apollo/client";

export const GET_APP_LAYOUT_CONTENT = gql`
  query GetAppLayoutContent {
    appLayout {
      id
      _id
      createdAt
      updatedAt
      FooterBody
      published_at
    }
  }
`;
