import { gql } from "@apollo/client";

export const GET_APP_CONFIG = gql`
  query GetAppConfigs {
    appConfig {
      config {
        key
        value
      }
    }
  }
`;
