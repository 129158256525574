import { gql } from "@apollo/client";
import { METADATA_CORE_DATA } from "graphql/fragments/metadataCoreData";
import { TAG_CORE_DATA } from "graphql/fragments/tagCoreData";

export const GET_POSTS = gql`
  query GetPosts($limit: Int, $start: Int, $where: JSON) {
    posts(limit: $limit, start: $start, where: $where) {
      id
      slug
      title
      description
      content
      viewCount
      cover {
        id
        url
      }
      tags {
        ...tagCoreData
      }
      post_category {
        slug
        CategoryName
      }
      Metadata {
        ...MetadataCoreData
      }
      published_at
    }
  }

  ${TAG_CORE_DATA}
  ${METADATA_CORE_DATA}
`;

export const GET_POSTS_BY_TAGS = gql`
  query GetPostsByTags($filter: JSON, $limit: Int, $start: Int) {
    posts(where: $filter, limit: $limit, start: $start) {
      id
      title
      description
      content
      viewCount
      cover {
        id
        url
      }
      tags {
        ...tagCoreData
      }
      slug
      published_at
      Metadata {
        ...MetadataCoreData
      }
    }
  }

  ${METADATA_CORE_DATA}
`;

export const GET_POST_BY_SLUG = gql`
  query GetPostBySlug($where: JSON) {
    posts(where: $where) {
      id
      slug
      title
      description
      content
      viewCount
      tags {
        ...tagCoreData
      }
      post_category {
        slug
        CategoryName
      }
      cover {
        id
        url
      }
      Metadata {
        ...MetadataCoreData
      }
      published_at
    }
  }

  ${TAG_CORE_DATA}
  ${METADATA_CORE_DATA}
`;
