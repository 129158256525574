import gql from "graphql-tag";

export const TAG_CORE_DATA = gql`
  fragment tagCoreData on Tags {
    id
    tagName
    Slug
    published_at
    createdAt
    updatedAt
  }
`;
