import { createIcon } from "@chakra-ui/icons";

// using `path`
export const LinkedInIcon = createIcon({
  displayName: "LinkedInIcon",
  viewBox: "0 0 27 27",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      d="M24.0754 0.417969H1.91953C0.858203 0.417969 0 1.25586 0 2.2918V24.5391C0 25.575 0.858203 26.418 1.91953 26.418H24.0754C25.1367 26.418 26 25.575 26 24.5441V2.2918C26 1.25586 25.1367 0.417969 24.0754 0.417969ZM7.71367 22.5738H3.8543V10.1629H7.71367V22.5738ZM5.78398 8.47188C4.54492 8.47188 3.54453 7.47148 3.54453 6.2375C3.54453 5.00352 4.54492 4.00312 5.78398 4.00312C7.01797 4.00312 8.01836 5.00352 8.01836 6.2375C8.01836 7.46641 7.01797 8.47188 5.78398 8.47188ZM22.1559 22.5738H18.3016V16.541C18.3016 15.1039 18.2762 13.2504 16.2957 13.2504C14.2898 13.2504 13.9852 14.8195 13.9852 16.4395V22.5738H10.1359V10.1629H13.8328V11.859H13.8836C14.3965 10.884 15.6559 9.85313 17.5297 9.85313C21.4348 9.85313 22.1559 12.4227 22.1559 15.7641V22.5738Z"
      fill="white"
    />
  ),
});
