import client from "apollo/apollo-client";
import { GET_APP_CONFIG } from "graphql/queries";
import { GetAppConfigs } from "types/GetAppConfigs";
import { config } from "./config";

export interface IAppConfigs {
  gettingStartedUrl: string;
  [key: string]: unknown;
}

export type WithAppConfigs<T extends object = {}> = T & {
  configs: IAppConfigs;
};

export const getAppConfigs = async () => {
  try {
    const { data } = await client.query<GetAppConfigs>({
      query: GET_APP_CONFIG,
      fetchPolicy: "network-only",
    });

    return appConfigsToObject(data.appConfig?.config ?? []);
  } catch (error) {
    return config;
  }
};

export const appConfigsToObject = (
  array: NonNullable<NonNullable<GetAppConfigs["appConfig"]>["config"]>
) => {
  const obj: Record<string, unknown> = {};

  for (const config of array) {
    if (config && !obj[config.key]) {
      obj[config.key] = config.value;
    }
  }

  const merged = {
    /** add default values */
    ...config,
    ...obj,
  };

  const { gettingStartedUrl, ...others } = merged;

  return {
    gettingStartedUrl: ensureUrl(gettingStartedUrl),
    ...others,
  };
};

const ensureUrl = (url: string) => (/^http/i.test(url) ? url : "http://" + url);
