import {
  Box,
  Button,
  Center,
  chakra,
  Collapse,
  Container,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Spacer,
  StackDivider,
  Text,
  useBreakpointValue,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";
import { IAppConfigs } from "@utils/appConfigs";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { HiMenu, HiX } from "react-icons/hi";

interface NavProp {
  disclosure: UseDisclosureReturn;
  isMenuShown: boolean;
  configs: IAppConfigs;
}

const Nav: React.FC<NavProp> = ({ disclosure, isMenuShown, configs }) => {
  const { pathname } = useRouter();
  const { isOpen, onToggle } = disclosure;
  const btnSize = useBreakpointValue({
    base: "mobile",
    md: "tablet",
    lg: "desktop",
  });

  return (
    <chakra.nav>
      <Container
        px="0"
        maxW={{
          base: "container.mobile",
          md: "container.tablet",
          lg: "container.desktop",
        }}
      >
        {/* desktop nav */}
        <Flex
          alignItems={"center"}
          // justifyContent={"space-between"}
          display={{ base: "none", lg: "flex" }}
        >
          <Box flex="1" minH="116px">
            <Link passHref href="/">
              <Image
                width="248px"
                height="auto"
                src="/images/logo-final.svg"
                alt="Growth Deputy Logo"
                _hover={{ cursor: "pointer" }}
                my="33px"
              />
            </Link>
          </Box>

          {isMenuShown && (
            <>
              <Spacer />
              <HStack
                spacing="72px"
                minH="116px"
                // alignItems={"center"}
                // justifyContent={"space-between"}
                color="#153C3C"
                mr="72px"
              >
                <Box minH="24px">
                  <Link passHref href="/">
                    <Text
                      variant="navItem"
                      {...(pathname === "/" && {
                        fontWeight: "bold",
                        fontFamily: "Circular Std",
                      })}
                    >
                      Home
                    </Text>
                  </Link>
                </Box>
                <Box minH="24px">
                  <Link passHref href="/about">
                    <Text
                      variant="navItem"
                      {...(pathname === "/about" && {
                        fontWeight: "bold",
                        fontFamily: "Circular Std",
                      })}
                    >
                      About Us
                    </Text>
                  </Link>
                </Box>
                <Box minH="24px">
                  <Link passHref href="/pricing">
                    <Text
                      variant="navItem"
                      {...(pathname === "/pricing" && {
                        fontWeight: "bold",
                        fontFamily: "Circular Std",
                      })}
                    >
                      Services & Pricing
                    </Text>
                  </Link>
                </Box>
                <Box minH="24px">
                  <Link passHref href="/blogs">
                    <Text
                      variant="navItem"
                      {...(pathname === "/blogs" && {
                        fontWeight: "bold",
                        fontFamily: "Circular Std",
                      })}
                    >
                      Blogs
                    </Text>
                  </Link>
                </Box>
              </HStack>
              <Box>
                <a
                  href={configs.gettingStartedUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button h={{ md: "48px", lg: "56px" }} px={{ lg: 6, md: 4 }}>
                    Get Started
                  </Button>
                </a>
              </Box>
            </>
          )}
        </Flex>

        {/* mobile nav */}
        <Flex p="24px" display={{ base: "flex", lg: "none" }}>
          <Image
            width="248px"
            height="auto"
            src="/images/logo-final.svg"
            alt="Growth Deputy Logo"
            mx={{ base: "auto", lg: "unset" }}
          />
          {isMenuShown && (
            <>
              <Spacer />
              {!isOpen && (
                <Center
                  display={{ base: "none", md: "flex", lg: "none" }}
                  mr="24px"
                >
                  <a
                    href={configs.gettingStartedUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Button size={btnSize}>Get Started</Button>
                  </a>
                </Center>
              )}
              <Center pr="8px">
                <IconButton
                  onClick={onToggle}
                  py={0}
                  icon={
                    isOpen ? (
                      <Icon as={HiX} height="32px" width="32px" />
                    ) : (
                      <Icon as={HiMenu} height="32px" width="32px" />
                    )
                  }
                  variant={"ghost"}
                  aria-label={"Toggle Navigation"}
                />
              </Center>
            </>
          )}
        </Flex>
        <Collapse
          in={isOpen}
          animateOpacity
          endingHeight="calc(100vh - 193.61px)"
        >
          <Box h="inherit" mt="65px" mx="24px">
            <Flex
              transition={"all .1s ease"}
              h="inherit"
              flexDirection="column"
            >
              <VStack
                spacing="16px"
                pb="24px"
                alignItems="flex-start"
                color="secondary.typography"
                divider={<StackDivider borderBottom="1px solid #E5E7EB" />}
                borderBottom="1px solid #E5E7EB"
              >
                <Box w="full" onClick={onToggle}>
                  <Link passHref href="/">
                    <Text
                      {...(pathname === "/" && {
                        fontWeight: "bold",
                        fontFamily: "Circular Std",
                      })}
                      variant="mobileNavItem"
                    >
                      Home
                    </Text>
                  </Link>
                </Box>
                <Box w="full" onClick={onToggle} mt="8px">
                  <Link passHref href="/about">
                    <Text
                      {...(pathname === "/about" && {
                        fontWeight: "bold",
                        fontFamily: "Circular Std",
                      })}
                      variant="mobileNavItem"
                    >
                      About Us
                    </Text>
                  </Link>
                </Box>
                <Box w="full" onClick={onToggle} mt="8px">
                  <Link passHref href="/pricing">
                    <Text
                      {...(pathname === "/pricing" && {
                        fontWeight: "bold",
                        fontFamily: "Circular Std",
                      })}
                      variant="mobileNavItem"
                    >
                      Services & Pricing
                    </Text>
                  </Link>
                </Box>
                {/* <Box onClick={onToggle}>
                  <Link passHref href="/blogs">
                    <Text
                      {...(pathname === "/blogs" && {
                        fontWeight: "bold",
                        fontFamily: "Circular Std",
                      })}
                      variant="mobileNavItem"
                    >
                      Resources
                    </Text>
                  </Link>
                </Box> */}
              </VStack>
              <Spacer />
              <Box w="full">
                <chakra.a
                  href={configs.gettingStartedUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button size={btnSize} mb="16px" w="full">
                    Get Started
                  </Button>
                </chakra.a>
              </Box>
            </Flex>
          </Box>
        </Collapse>
      </Container>
    </chakra.nav>
  );
};

export default Nav;
