import { gql } from "@apollo/client";
import { METADATA_CORE_DATA } from "graphql/fragments/metadataCoreData";

export const GET_ABOUT_US_PAGE_CONTENT = gql`
  query GetAboutUsPageContent {
    aboutUsPage {
      id
      _id
      createdAt
      updatedAt
      Section1Headline
      Section1Body {
        id
        Content
      }
      Section2Headline
      Section2Body
      Section3Body
      published_at
      Metadata {
        ...MetadataCoreData
      }
    }
  }

  ${METADATA_CORE_DATA}
`;
