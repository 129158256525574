import { gql } from "@apollo/client";
import { METADATA_CORE_DATA } from "graphql/fragments/metadataCoreData";

export const GET_HOME_PAGE_CONTENT = gql`
  query GetHomePageContent {
    homePage {
      id
      createdAt
      updatedAt
      Section1Headline
      Section3Headline
      published_at
      Section1Body {
        id
        _id
        Content
      }
      Section3Body {
        id
        _id
        Content
      }
      Section3Highlights {
        id
        _id
        Content
      }
      Testimonials {
        id
        _id
        Message
        Name
        Position
        Company
      }
      Metadata {
        ...MetadataCoreData
      }
    }
  }

  ${METADATA_CORE_DATA}
`;
