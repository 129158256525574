import { appConfigsToObject } from "@utils/appConfigs";
import { config } from "@utils/config";
import { fetcher } from "@utils/swr";
import { print } from "graphql";
import { GET_APP_CONFIG } from "graphql/queries";
import useSWR from "swr";
import { GetAppConfigs } from "types/GetAppConfigs";

const useAppConfigs = () => {
  const { data, error } = useSWR<GetAppConfigs>(
    print(GET_APP_CONFIG),
    fetcher,
    { revalidateOnMount: true, revalidateOnFocus: false }
  );

  if (error) return config;

  return appConfigsToObject(data?.appConfig?.config ?? []);
};

export default useAppConfigs;
