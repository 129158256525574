import { chakra, useDisclosure } from "@chakra-ui/react";
import useAppConfigs from "@hooks/useAppConfigs";
import React from "react";
import type { SWRConfiguration } from "swr";
import useSWR from "swr";
import { GetAppLayoutContent } from "types/GetAppLayoutContent";
import { fetcher } from "utils/swr";
import Footer from "./Footer";
import Nav from "./Nav";

const config: SWRConfiguration = {
  // fallbackData: "fallback",
  revalidateOnMount: true,
};

const Layout: React.FC<{
  isMenuShown?: boolean;
}> = ({ children, isMenuShown = true }) => {
  const appConfigs = useAppConfigs();
  const navDisclosure = useDisclosure();
  const { data } = useSWR<GetAppLayoutContent>(
    `query GetAppLayoutContent {
      appLayout {
        id
        _id
        createdAt
        updatedAt
        FooterBody
        published_at
      }
    }`,
    fetcher,
    config
  );

  return (
    <>
      <Nav
        disclosure={navDisclosure}
        configs={appConfigs}
        isMenuShown={isMenuShown}
      />
      <chakra.main display={navDisclosure.isOpen ? "none" : "block"}>
        {children}
      </chakra.main>
      <Footer
        configs={appConfigs}
        display={navDisclosure.isOpen ? "none" : "block"}
        layoutContent={data?.appLayout}
      />
    </>
  );
};

export default Layout;
