import {
  Box,
  BoxProps,
  Center,
  chakra,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { IAppConfigs } from "@utils/appConfigs";
import { LinkedInIcon } from "icons/LinkedInIcon";
import Link from "next/link";
import React from "react";
import { GetAppLayoutContent } from "types/GetAppLayoutContent";

interface FooterProps extends BoxProps {
  layoutContent?: GetAppLayoutContent["appLayout"];
  configs: IAppConfigs;
}

const Footer: React.FC<FooterProps> = ({ layoutContent, configs, ...rest }) => {
  return (
    <chakra.footer
      bgColor="secondary.typography"
      minH="528px"
      px={{ base: "24px", lg: "0px" }}
      py={{ base: "42px", lg: "0px" }}
      {...rest}
    >
      <Container
        maxW={{
          base: "container.mobile",
          md: "container.tablet",
          lg: "container.desktop",
        }}
        borderBottom="1px solid #FFFFFF"
        h="full"
        mb="30px"
        px={"0px"}
      >
        <Flex
          flexDirection={{ base: "column-reverse", md: "row" }}
          alignItems={{ base: "flex-start", md: "center" }}
          justifyContent={"space-between"}
          minH="calc(528px - 89px)"
          color="white"
        >
          <Box flex="1">
            <Flex
              flexDirection="column"
              mt={{ base: "72px", lg: "0px" }}
              mb={{ base: "34px", lg: "0px" }}
            >
              <Heading mb="16px" variant="h3">
                GrowthDeputy
              </Heading>
              <Text
                mb="32px"
                maxW={{ base: "unset", md: "299px", lg: "397px" }}
                variant="paragraph-2"
              >
                {layoutContent?.FooterBody}
              </Text>
              <HStack spacing="40px">
                <Center _hover={{ cursor: "pointer" }}>
                  <Link
                    passHref
                    href="https://www.linkedin.com/company/growthdeputy/about"
                  >
                    <Icon
                      as={LinkedInIcon}
                      h={{ base: "27px" }}
                      w={{ base: "27px" }}
                    />
                  </Link>
                </Center>
              </HStack>
            </Flex>
          </Box>
          <Flex
            flex="1"
            justifyContent={"space-between"}
            flexDirection={{ base: "column", lg: "row" }}
          >
            <VStack flex="1" spacing={4} align="stretch">
              <Link passHref href="/">
                <Text variant="paragraph-2" _hover={{ cursor: "pointer" }}>
                  Home
                </Text>
              </Link>
              <Link passHref href="/about">
                <Text variant="paragraph-2" _hover={{ cursor: "pointer" }}>
                  About Us
                </Text>
              </Link>
              <Link passHref href="/pricing">
                <Text variant="paragraph-2" _hover={{ cursor: "pointer" }}>
                  Services & Pricing
                </Text>
              </Link>
              <a
                href={configs.gettingStartedUrl}
                rel="noreferrer"
                target="_blank"
              >
                <Text variant="paragraph-2">Get Started</Text>
              </a>
            </VStack>
          </Flex>
        </Flex>
      </Container>

      <Box color="secondary.white" textAlign={{ base: "start", lg: "center" }}>
        © 2022 Growth Deputy
      </Box>
    </chakra.footer>
  );
};

export default Footer;
