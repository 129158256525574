import { gql } from "@apollo/client";

export const GET_CAMPAIGNS = gql`
  # Write your query or mutation here
  query GetCampaigns {
    campaigns {
      id
      Name
      Slug
      Configs {
        GettingStartedUrl
        LearnMoreAnchor
      }
      Category {
        Name
        Slug
      }
      Content {
        Section1 {
          id
          Heading
          Body
          OrderNum
          Image {
            id
            url
          }
        }
        Section2 {
          id
          Heading
          Body
          OrderNum
          PositionRate
        }
        Section2Tasks {
          id
          TaskItem
        }
        Section2Skills {
          id
          SkillItem
        }
        Section3 {
          id
          Heading
          Body
          OrderNum
        }
        Section3Steps {
          id
          ImageIcon {
            id
            url
          }
          Heading
          Body
        }
        Section4 {
          id
          Heading
          Body
          OrderNum
          Image {
            id
            url
          }
        }
        Section5 {
          id
          Heading
          OrderNum
        }
        Section5Reasons {
          id
          ImageIcon {
            id
            url
          }
          Body
        }
        Section6 {
          id
          OrderNum
        }
        Section6Testimonials {
          id
          _id
          Message
          Name
          Position
          Company
        }
        Section7 {
          id
          Heading
          Body
          OrderNum
        }
        Section7Values {
          id
          ImageIcon {
            id
            url
          }
          Heading
          Body
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_BY_SLUG = gql`
  query GetCampaignBySlug($filters: JSON) {
    campaigns(where: $filters) {
      id
      Name
      Slug
      Configs {
        GettingStartedUrl
        LearnMoreAnchor
      }
      Category {
        Name
        Slug
      }
      Content {
        Section1 {
          id
          Heading
          Body
          OrderNum
          Image {
            id
            url
          }
        }
        Section2 {
          id
          Heading
          Body
          OrderNum
          PositionRate
        }
        Section2Tasks {
          id
          TaskItem
        }
        Section2Skills {
          id
          SkillItem
        }
        Section3 {
          id
          Heading
          Body
          OrderNum
        }
        Section3Steps {
          id
          ImageIcon {
            id
            url
          }
          Heading
          Body
        }
        Section4 {
          id
          Heading
          Body
          OrderNum
          Image {
            id
            url
          }
        }
        Section5 {
          id
          Heading
          OrderNum
        }
        Section5Reasons {
          id
          ImageIcon {
            id
            url
          }
          Body
        }
        Section6 {
          id
          OrderNum
        }
        Section6Testimonials {
          id
          _id
          Message
          Name
          Position
          Company
        }
        Section7 {
          id
          Heading
          Body
          OrderNum
        }
        Section7Values {
          id
          ImageIcon {
            id
            url
          }
          Heading
          Body
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_BY_SLUG_UNRELEASED = gql`
  query GetCampaignBySlugUnreleased($filters: JSON) {
    campaigns(where: $filters) {
      id
      Name
      Slug
      Configs {
        GettingStartedUrl
        LearnMoreAnchor
      }
      Category {
        Name
        Slug
      }
      Content {
        Section1 {
          id
          Heading
          Body
          OrderNum
          Image {
            id
            url
          }
        }
        Section2 {
          id
          Heading
          Body
          OrderNum
          PositionRate
        }
        Section2Tasks {
          id
          TaskItem
        }
        Section2Skills {
          id
          SkillItem
        }
        Section3 {
          id
          Heading
          Body
          OrderNum
        }
        Section3Steps {
          id
          ImageIcon {
            id
            url
          }
          Heading
          Body
        }
        Section4 {
          id
          Heading
          Body
          OrderNum
          Image {
            id
            url
          }
        }
        Section5 {
          id
          Heading
          OrderNum
        }
        Section5Reasons {
          id
          ImageIcon {
            id
            url
          }
          Body
        }
        Section6 {
          id
          OrderNum
        }
        Section6Testimonials {
          id
          _id
          Message
          Name
          Position
          Company
        }
        Section7 {
          id
          Heading
          Body
          OrderNum
        }
        Section7Values {
          id
          ImageIcon {
            id
            url
          }
          Heading
          Body
        }
      }
      campaign_order {
        id
        order
      }
    }
  }
`;
